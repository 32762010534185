import React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/vercel/workpath0/packages/swarm-docs/src/components/layout-mdx.js";
import { Radio } from '@meetup/swarm-components';
import Example, { GroupRadioButtonsExample } from '../../components/examples/radio';
import PropsTable from '../../components/propstable';
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <h1>{`Radio`}</h1>
    <hr></hr>
    <p>{`A wrapper for an html `}<inlineCode parentName="p">{`<input type="radio">`}</inlineCode>{`.`}</p>
    <br />
    <br />
    <h3>{`Snippet`}</h3>
    <hr></hr>
    <p>{`Use this interactive snippet to try the component:`}</p>
    <br />
    <pre><code parentName="pre" {...{
        "className": "language-.jsx"
      }}>{`<Radio name="swarm" label="swarm" value="1" checked={true} onChange={(e) => alert(e.target.value)} />
`}</code></pre>
    <br />
    <br />
    <h3>{`Props`}</h3>
    <hr></hr>
    <br />
    <PropsTable propMetaData={Radio.__docgenInfo} mdxType="PropsTable" />
    <br />
    <br />
    <h3>{`a11y`}</h3>
    <hr></hr>
    <br />
    <p>{`Radio buttons should be used when only one option from a group can be selected.`}</p>
    <br />
    <p><strong parentName="p">{`Keyboard interactions:`}</strong></p>
    <ul>
      <li parentName="ul"><strong parentName="li">{`↑/↓`}</strong>{` or `}<strong parentName="li">{`←/→`}</strong>{`    ... to select an option.`}</li>
      <li parentName="ul"><strong parentName="li">{`Tab`}</strong>{`               ... to move to the next element.`}</li>
    </ul>
    <br />
    <br />
    <h3>{`Examples`}</h3>
    <hr></hr>
    <br />
    <p><strong parentName="p">{`These are examples of single (not-grouped) Radio buttons:`}</strong></p>
    <br />
    <Example mdxType="Example" />
    <br />
    <p><strong parentName="p">{`This is an example of grouped radio buttons and how to manage state:`}</strong></p>
    <br />
    <GroupRadioButtonsExample mdxType="GroupRadioButtonsExample" />

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      